<template>
    <v-app>
        <scanner-interface @sendProduct = "sendProduct"/>
        <scanned-product :product="scannedProduct"/>
        <mobile-navigation />
    </v-app>
</template>

<script>

import ScannerInterface from "../components/custom/ScannerInterface";
import MobileNavigation from '@/layouts/base/MobileNavigationBar'
import ScannedProduct from "../components/custom/ScannedProduct";

export default {
    components: {
        MobileNavigation,
        ScannerInterface,
        ScannedProduct
    },
    data () {
        return {
            scannedProduct: {}
        }
    },
    methods: {
        sendProduct(product) {
            this.scannedProduct = product
        }
    }
    
}
</script>

<style>

</style>
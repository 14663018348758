<template>
  <qrcode-scanner
    :qrbox="250" 
    :fps="20" 
    style="width: 100%;"
    @result="onScan"
  />
</template>

<script>
import ProductsService from "../../services/ProductsService"
export default {
  props: {
    store_id: {
      type: [String],
      description: "Store id",
    }
  },
  data() {
    return {
      product: {}
    }
  },
  methods: {
    async onScan (decodedText, decodedResult) {
      this.product = (await ProductsService.scan(decodedText, store_id)).data.productData
      this.emitProduct()
    },
    emitProduct() {
      this.$emit('sendProduct', this.product)
    }
  }
}
</script>
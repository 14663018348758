<template>
  <div class="scanner_product">
    <div v-if="Object.keys(product).length !== 0" class="scanner_product--container">
        <span v-if="product.name" class="scanner_product--text text-left">{{ product.name }}</span>
        <span v-else class="scanner_product--text text-left">{{ product.title }}</span>
        <span class="scanner_product--text text-right">{{ product.price }}</span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    product: {
      type: [Object],
      description: "Scanned product",
    }
  },
  methods: {
    
  }
}
</script>